.menu-flags {
  //   border: 1px solid red;
}
.menu-flags-btn {
  border-color: #019ed3;
  background: transparent;
  border: 1px solid #019ed3;
  //   border-right: 0px;
  //   border-bottom-left-radius: 5px;
  //   padding: 21;
  //   padding-left: 50px;
  width: 100%;
  font-size: 16px;
  height: 44px;
  color: #019ed3;
}

// buttonStyle={{
//     borderColor: "#019ed3",
//     background: "transparent",
//     border: "1px solid #019ed3 ",
//     borderRight: "0px",
//     borderBottomLeftRadius: "5px",
//   }}
//   inputStyle={{
//     padding: 21,
//     paddingLeft: 50,
//     width: "100%",
//     fontSize: 16,
//     borderColor: "#019ed3",
//     background: "transparent",
//     color: "#019ed3",
//   }}
//   containerStyle={{
//     marginBottom: 25,
//   }}
